import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { dashboardAllData } from "../data/dummyData";

import {
  currentYear,
  generateYearOptions,
  generateQuarterOptions,
  generateMonthOptions,
  generateDashboardFilterValues,
} from "../utils/filterUtils";

// Generate the initial options for years, months, quarters, products, and regions
const monthOptions = generateMonthOptions(currentYear);
const quarterOptions = generateQuarterOptions(currentYear);

export const getDashboarData = createAsyncThunk(
  "/dashboard/getDashboardData",
  async (__, { getState }) => {
    const { dashboard } = getState();

    const filters = generateDashboardFilterValues(dashboard.filters);

    console.log(filters);

    await new Promise((resolve) => setTimeout(resolve, 500));

    const data = dashboardAllData;
    return {
      data,
      timeFrame: dashboard.filters.filterTimeBy,
      currency: dashboard.filters.currency,
    };
  }
);

const initialState = {
  loading: true,
  error: false,
  data: null,
  yearOptions: null,
  monthOptions: monthOptions,
  quarterOptions: quarterOptions,
  regionsOptions: null,
  productsOptions: null,
  viewOptions: null,
  startingDate: null,
  timeFrame: "month",
  filters: {
    filterTimeBy: "month",
    year: null,
    month: monthOptions[monthOptions.length - 1],
    quarter: quarterOptions[quarterOptions.length - 1],
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardFiltersOptions: (state, { payload }) => {
      const { userData } = payload;
      // Extract starting date from user data and generate years options based on it
      const startingDate = userData.startingDate;
      const startingYear = new Date(startingDate).getFullYear();
      const yearOptions = generateYearOptions(startingYear);

      // Update the dashboard state with filter options and starting date
      state.yearOptions = yearOptions;
      state.filters.year = yearOptions[0];
      state.startingDate = startingDate;
      state.productsOptions = userData.filtersOptions.products;
      state.regionsOptions = userData.filtersOptions.regions;
      state.viewOptions = userData.filtersOptions.view;
      state.currencyOptions = userData.filtersOptions.currency;
      state.currency = userData.filtersOptions.currency[0];
      state.filters.currency = userData.filtersOptions.currency[0];
    },
    // Set the filter by
    setFilterBy: (state, { payload }) => {
      const { filterTimeBy } = payload;
      state.filters.filterTimeBy = filterTimeBy;
      state.filters.year = state.yearOptions[0];
      state.monthOptions = initialState.monthOptions;
      state.quarterOptions = initialState.quarterOptions;
      if (filterTimeBy !== "year") {
        state.filters.month = monthOptions[monthOptions.length - 1];
        state.filters.quarter = quarterOptions[quarterOptions.length - 1];
      }
    },
    // Set the selected year and update month and quarter options accordingly
    setSelectedYear: (state, { payload }) => {
      const { selectedYear } = payload;
      state.filters.year = selectedYear;
      const monthOptions = generateMonthOptions(
        selectedYear,
        state.startingDate
      );
      const quarterOptions = generateQuarterOptions(
        selectedYear,
        state.startingDate
      );
      state.monthOptions = monthOptions;
      state.quarterOptions = quarterOptions;
      if (selectedYear.toString() === currentYear.toString()) {
        state.filters.month = monthOptions[monthOptions.length - 1];
        state.filters.quarter = quarterOptions[quarterOptions.length - 1];
      } else {
        state.filters.month = monthOptions[0];
        state.filters.quarter = quarterOptions[0];
      }
    },
    // Update a filter value
    updateFilter: (state, { payload }) => {
      const { selectedFilter, name } = payload;
      // If the selected value is an array (from multiple choice filter)
      if (Array.isArray(selectedFilter)) {
        // If the value is an empty array, remove the filter value
        if (selectedFilter.length === 0) {
          delete state.filters[name];
        }
        // If it's not an empty array, assign the new value to the filter object
        else {
          state.filters[name] = selectedFilter;
        }
      }
      // If the selected value is a string coming from a single choice filter
      else {
        // If the value is null, remove the filter value
        if (!selectedFilter) {
          delete state.filters[name];
        }
        // If the value is not null, assign it to the filter object
        else {
          state.filters[name] = selectedFilter;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboarData.pending, (state) => {
        state.loading = true;
        state.data = null;
      })
      .addCase(getDashboarData.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.timeFrame = payload.timeFrame;
        state.currency = payload.currency;
        state.loading = false;
      })
      .addCase(getDashboarData.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  setDashboardFiltersOptions,
  getDashboardData,
  updateFilter,
  setFilterBy,
  setSelectedYear,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
