import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { dummyUserData } from "../data/dummyUser";

import { setDashboardFiltersOptions } from "./dashboardSlice";
import { setReportsFiltersOptions } from "./reportsSlice";

export const getUser = createAsyncThunk(
  "/user/getUser",
  async (__, { dispatch }) => {
    const userData = dummyUserData;
    await new Promise((resolve) => setTimeout(resolve, 500));
    dispatch(setDashboardFiltersOptions({ userData }));
    dispatch(setReportsFiltersOptions({ userData }));
    return { userData };
  }
);

const initialState = {
  isAuthenticated: false,
  userData: null,
  loading: true,
  error: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userData = payload.userData;
        state.isAuthenticated = true;
      })
      .addCase(getUser.rejected, (state) => {
        state.loading = false;
        state.isAuthenticated = false;
      });
  },
});

export default userSlice.reducer;
