// Get the current year, month, and quarter
export const currentYear = new Date().getFullYear();
export const currentMonth = new Date().getMonth();
export const currentQuarter = Math.ceil((currentMonth + 1) / 3);

// Helper function to generate an array of year options
export const generateYearOptions = (startYear) => {
  const years = [];
  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }
  return years.reverse();
};

// Helper function to generate an array of month options based on the selected year
export const generateMonthOptions = (selectedYear, startingDate) => {
  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  let startMonth = 0;

  if (startingDate) {
    const [startYear, startMonthIndex] = startingDate.split("-");
    if (parseInt(startYear) === selectedYear) {
      startMonth = parseInt(startMonthIndex) - 1;
    }
  }

  let endMonth = 11;
  if (selectedYear.toString() === currentYear.toString()) {
    endMonth = currentMonth;
  }

  const monthOptions = months.slice(startMonth, endMonth + 1);

  return monthOptions;
};

// Helper function to generate an array of quarter options based on the selected year
export const generateQuarterOptions = (selectedYear, startingDate) => {
  const quarters = ["Q1", "Q2", "Q3", "Q4"];
  let startQuarter = 0;

  if (startingDate) {
    const startDateObject = new Date(startingDate);

    const startYear = startDateObject.getFullYear();
    const startQuarterIndex = Math.floor(startDateObject.getMonth() / 3);

    if (startYear === selectedYear) {
      startQuarter = startQuarterIndex;
    }
  }

  let endQuarter = 4;
  if (selectedYear.toString() === currentYear.toString()) {
    endQuarter = currentQuarter;
  }

  const quarterOptions = quarters.slice(startQuarter, endQuarter);

  return quarterOptions;
};

export const generateGranularityOptionsByDays = (diffInDays) => {
  if (diffInDays <= 7) {
    return ["daily"];
  } else if (diffInDays > 7 && diffInDays < 30) {
    return ["daily", "weekly"];
  } else if (diffInDays >= 30 && diffInDays < 91) {
    return ["weekly", "monthly"];
  } else if (diffInDays >= 91 && diffInDays <= 270) {
    return ["weekly", "monthly", "quarterly"];
  } else if (diffInDays > 270) {
    return ["monthly", "quarterly"];
  }
};

export const generateGranularityOptionsByTimeframe = (timeFrameValue) => {
  if (timeFrameValue === "today" || timeFrameValue === "week") {
    return ["daily"];
  } else if (timeFrameValue === "month") {
    return ["daily", "weekly"];
  } else if (timeFrameValue === "quarter") {
    return ["weekly", "monthly"];
  } else if (timeFrameValue === "year") {
    return ["monthly", "quarterly"];
  }
};

export const generateDashboardFilterValues = (filters) => {
  // Destructure filter values from the filters object
  const { filterTimeBy, year, month, quarter, regions, products, view } =
    filters;

  let filterValues = {};

  // Determine the time frame based on the selected filter time
  if (filterTimeBy === "month") {
    // If filtering by month, include year, filter time, and selected month
    filterValues.timeFrame = [parseInt(year), filterTimeBy, month];
  } else if (filterTimeBy === "quarter") {
    // If filtering by quarter, include year, filter time, and selected quarter
    filterValues.timeFrame = [parseInt(year), filterTimeBy, quarter];
  } else {
    // If filtering by year, include year and filter time
    filterValues.timeFrame = [parseInt(year), filterTimeBy];
  }

  if (regions) {
    filterValues.regions = regions;
  }

  if (products) {
    filterValues.products = products;
  }

  if (view) {
    filterValues.view = view;
  }

  return filterValues;
};

export const generateReportsFilterValues = (filters) => {
  const {
    type,
    typeCategory,
    selectedCustomReport,
    currency,
    regions,
    products,
    view,
    scenario,
    timeFrame,
    filterTimeBy,
    rangeSelection,
    timeGranularity,
    segment,
    customersType,
    year,
    month,
    quarter,
    startDate,
    endDate,
  } = filters;

  let filterValues = {};

  filterValues.type = type;

  filterValues.currency = currency;

  if (regions) {
    filterValues.regions = regions;
  }

  if (products) {
    filterValues.products = products;
  }
  // if the type is one of the financial types [Income statement, Cashflow statement, Balance sheet]
  if (typeCategory === "financial") {
    if (filterTimeBy === "custom" && (!startDate || !endDate)) {
      return null;
    }

    filterValues.filterTimeBy = filterTimeBy;

    switch (filterTimeBy) {
      case "month":
        filterValues.timeFrame = [parseInt(year), filterTimeBy, month];
        break;
      case "quarter":
        filterValues.timeFrame = [parseInt(year), filterTimeBy, quarter];
        break;
      case "year":
        filterValues.timeFrame = [parseInt(year), filterTimeBy];
        break;
      case "custom":
        filterValues.timeFrame = [startDate, endDate];
        break;
      default:
        break;
    }

    filterValues.view = view;
    filterValues.scenario = scenario;
  } else {
    if (timeFrame === "custom" && (!startDate || !endDate)) {
      return null;
    }

    if (timeFrame === "custom") {
      filterValues.timeFrame = [startDate, endDate, timeGranularity];
    } else {
      filterValues.timeFrame = [timeFrame, timeGranularity];
    }

    if (rangeSelection) {
      filterValues.rangeSelection = rangeSelection;
    }

    if (type === "custom reports" && selectedCustomReport) {
      filterValues.selectedCustomReport = selectedCustomReport;
    } else {
      filterValues.segment = segment;
      filterValues.customersType = customersType;
    }
  }

  return filterValues;
};
