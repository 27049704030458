import { useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "./store/userSlice";

import { Navigate, Route, Routes } from "react-router-dom";

import SplashScreen from "./components/UI/SplashScreen";
import CenteredSpinner from "./components/UI/CenteredSpinner";

const Layout = lazy(() => import("./components/global/Layout"));

function App() {
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  if (userState.loading) {
    return <SplashScreen />;
  }

  return (
    <Suspense fallback={<CenteredSpinner />}>
      <Routes>
        <Route
          path="/login"
          element={
            userState.isAuthenticated ? <Navigate to="/" /> : <div>Login</div>
          }
        />
        <Route
          path="/*"
          element={
            userState.isAuthenticated ? <Layout /> : <Navigate to="/login" />
          }
        />
      </Routes>
    </Suspense>
  );
}

export default App;
