// Returns the number of dats passed from the beginning of the current {value} to today
export const getDaysPassed = (value) => {
  const currentDate = new Date();

  if (value === "week") {
    const currentDay = currentDate.getDay();
    return currentDay === 0 ? 7 : currentDay;
  }

  if (value === "month") {
    return currentDate.getDate();
  }

  if (value === "quarter") {
    const currentMonth = currentDate.getMonth();
    const currentQuarter = Math.floor(currentMonth / 3) + 1;

    const quarterStartMonth = (currentQuarter - 1) * 3;
    const quarterStartDate = new Date(
      currentDate.getFullYear(),
      quarterStartMonth,
      1
    );

    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const diffInDays = Math.floor(
      (currentDate - quarterStartDate) / millisecondsPerDay
    );
    return diffInDays + 1;
  }

  if (value === "year") {
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    return Math.floor((currentDate - startOfYear) / millisecondsPerDay) + 1;
  }

  return 0;
};
