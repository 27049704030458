import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  currentYear,
  generateYearOptions,
  generateQuarterOptions,
  generateMonthOptions,
  generateGranularityOptionsByDays,
  generateGranularityOptionsByTimeframe,
  generateReportsFilterValues,
} from "../utils/filterUtils";

import {
  viewOptions,
  typeOptions,
  filterTimeByOptions,
  timeFrameOptions,
  rangeSelectionOptions,
  timeGranularityOptions,
  segmentOptions,
  customersTypeOptions,
  scenarioOptions,
  typeCategoryMapping,
} from "../data/reportsFilterOptions";

import { getDaysPassed } from "../utils/dateUtils";

import { generateReportsData } from "../data/dummyReports";

const monthOptions = generateMonthOptions(currentYear);
const quarterOptions = generateQuarterOptions(currentYear);

export const getReportsData = createAsyncThunk(
  "/reports/getReportsData",
  async (__, { getState }) => {
    const { reports } = getState();
    if (
      reports.filters.type === "custom reports" &&
      !reports.filters.selectedCustomReport
    ) {
      return { data: {} };
    }
    if (
      (reports.filters.filterTimeBy === "custom" ||
        reports.filters.timeFrame === "custom") &&
      (!reports.filters.startDate || !reports.filters.endDate)
    ) {
      console.log(reports.filters);
      throw new Error("Please select a valid time range");
    }
    const filters = generateReportsFilterValues(reports.filters);
    console.log(filters);
    const data = generateReportsData(filters);
    await new Promise((resolve) => setTimeout(resolve, 500));
    return { data, dataReportType: reports.filters.type };
  }
);

export const saveCustomReport = createAsyncThunk(
  "/reports/saveCustomReport",
  async ({ reportType, reportName, reportSchema }, { rejectWithValue }) => {
    try {
      const data = { reportType, reportName, reportSchema };
      console.log(data);
      await new Promise((resolve) => setTimeout(resolve, 500));
      return { reportName };
    } catch (err) {
      const error = err.response
        ? err.response.data.message
        : "something went wrong";
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  loading: true,
  error: false,
  collapsed: false,
  data: null,
  startingDate: null,
  filterTimeByOptions: filterTimeByOptions,
  typeOptions: typeOptions,
  timeFrameOptions: timeFrameOptions,
  rangeSelectionOptions: rangeSelectionOptions,
  timeGranularityOptions: timeGranularityOptions,
  segmentOptions: segmentOptions,
  customersTypeOptions: customersTypeOptions,
  yearOptions: null,
  monthOptions: monthOptions,
  quarterOptions: quarterOptions,
  productsOptions: null,
  regionsOptions: null,
  scenarioOptions: scenarioOptions,
  currencyOptions: null,
  viewOptions: viewOptions,
  customReports: null,
  isSaving: false,
  filters: {
    type: typeOptions[0],
    currency: null,
    filterTimeBy: filterTimeByOptions[0],
    timeFrame: timeFrameOptions[0],
    rangeSelection: null,
    timeGranularity: timeGranularityOptions[0],
    segment: segmentOptions[0],
    customersType: customersTypeOptions[0],
    year: null,
    month: monthOptions[monthOptions.length - 1],
    quarter: quarterOptions[quarterOptions.length - 1],
    typeCategory: "financial",
    view: viewOptions[0],
    scenario: scenarioOptions[0],
    selectedCustomReport: null,
  },
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setReportsFiltersOptions: (state, { payload }) => {
      const { userData } = payload;
      const startingDate = userData.startingDate;
      const startingYear = new Date(startingDate).getFullYear();
      const yearOptions = generateYearOptions(startingYear);
      state.yearOptions = yearOptions;
      state.filters.year = yearOptions[0];
      state.startingDate = startingDate;
      state.productsOptions = userData.filtersOptions.products;
      state.regionsOptions = userData.filtersOptions.regions;
      state.currencyOptions = userData.filtersOptions.currency;
      state.filters.currency = userData.filtersOptions.currency[0];
      state.customReports = userData.customReports;
      state.filters.selectedCustomReport = userData.customReports[0];
    },
    toggleCollapse: (state) => {
      state.collapsed = !state.collapsed;
    },
    resetCollapse: (state) => {
      state.collapsed = false;
    },
    setReportType: (state, { payload }) => {
      const { type } = payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          type: type,
          typeCategory: typeCategoryMapping[type],
        },
      };
    },
    // Set the filter By
    setFilterTimeBy: (state, { payload }) => {
      const { filterTimeBy } = payload;
      state.filters.filterTimeBy = filterTimeBy;
      state.filters.year = state.yearOptions[0];
      state.monthOptions = initialState.monthOptions;
      state.quarterOptions = initialState.quarterOptions;
      state.filters.startDate = null;
      state.filters.endDate = null;
      if (filterTimeBy !== "year") {
        state.filters.month = monthOptions[monthOptions.length - 1];
        state.filters.quarter = quarterOptions[quarterOptions.length - 1];
      }
    },
    setFilterTimeframe: (state, { payload }) => {
      const { timeFrame } = payload;
      state.filters.timeFrame = timeFrame;

      if (timeFrame === "custom") {
        state.timeGranularityOptions = [];
        state.filters.timeGranularity = null;
        state.filters.startDate = null;
        state.filters.endDate = null;
      } else {
        const granularityOptions =
          generateGranularityOptionsByTimeframe(timeFrame);
        state.timeGranularityOptions = granularityOptions;
        state.filters.timeGranularity = granularityOptions[0];
        if (["week", "month", "quarter", "year"].includes(timeFrame)) {
          state.filters.rangeSelection = rangeSelectionOptions[0];
        } else {
          state.filters.rangeSelection = null;
        }
      }
    },
    setRangeSelection: (state, { payload }) => {
      const { rangeSelection } = payload;
      state.filters.rangeSelection = rangeSelection;
      if (rangeSelection === "trailing") {
        const granularityOptions = generateGranularityOptionsByTimeframe(
          state.filters.timeFrame
        );
        state.timeGranularityOptions = granularityOptions;
        state.filters.timeGranularity = granularityOptions[0];
      } else {
        const daysPassed = getDaysPassed(state.filters.timeFrame);
        const granularityOptions = generateGranularityOptionsByDays(daysPassed);
        state.timeGranularityOptions = granularityOptions;
        state.filters.timeGranularity = granularityOptions[0];
      }
    },
    setCustomDateRange: (state, { payload }) => {
      const [startDate, endDate] = payload;
      state.filters.startDate = startDate;
      state.filters.endDate = endDate;
      if (startDate && endDate) {
        const diffInDays =
          (new Date(endDate).getTime() - new Date(startDate).getTime()) /
          (1000 * 60 * 60 * 24);
        const granularityOptions = generateGranularityOptionsByDays(diffInDays);
        state.timeGranularityOptions = granularityOptions;
        state.filters.timeGranularity = granularityOptions[0];
      } else {
        state.timeGranularityOptions = [];
        state.filters.timeGranularity = null;
      }
    },
    setSelectedYear: (state, { payload }) => {
      const { selectedYear } = payload;
      state.filters.year = selectedYear;
      const monthOptions = generateMonthOptions(
        selectedYear,
        state.startingDate
      );
      const quarterOptions = generateQuarterOptions(
        selectedYear,
        state.startingDate
      );
      state.monthOptions = monthOptions;
      state.quarterOptions = quarterOptions;
      if (selectedYear.toString() === currentYear.toString()) {
        state.filters.month = monthOptions[monthOptions.length - 1];
        state.filters.quarter = quarterOptions[quarterOptions.length - 1];
      } else {
        state.filters.month = monthOptions[0];
        state.filters.quarter = quarterOptions[0];
      }
    },
    updateFilter: (state, { payload }) => {
      const { selectedFilter, name } = payload;
      if (Array.isArray(selectedFilter)) {
        if (selectedFilter.length === 0) {
          delete state.filters[name];
        } else {
          state.filters[name] = selectedFilter;
        }
      } else {
        if (!selectedFilter) {
          delete state.filters[name];
        } else {
          state.filters[name] = selectedFilter;
        }
      }
    },
    setSelectedReport: (state, { payload }) => {
      state.filters.selectedCustomReport = payload.reportName;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReportsData.pending, (state) => {
        state.loading = true;
        state.data = null;
        state.error = false;
      })
      .addCase(getReportsData.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.dataReportType = payload.dataReportType;
        state.loading = false;
        state.error = false;
      })
      .addCase(getReportsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(saveCustomReport.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(saveCustomReport.fulfilled, (state, { payload }) => {
        const { reportName } = payload;
        if (state.customReports) {
          state.customReports = [...state.customReports, reportName];
        } else {
          state.customReports = [reportName];
        }
        state.filters.selectedCustomReport = reportName;
        state.isSaving = false;
        state.loading = true;
      })
      .addCase(saveCustomReport.rejected, (state) => {
        state.isSaving = false;
        state.error = true;
      });
  },
});

export const {
  setReportsFiltersOptions,
  toggleCollapse,
  resetCollapse,
  setReportType,
  updateFilter,
  setFilterTimeBy,
  setFilterTimeframe,
  setRangeSelection,
  setCustomDateRange,
  setSelectedYear,
  setSelectedReport,
} = reportsSlice.actions;

export default reportsSlice.reducer;
