import React from "react";

import CircularProgress from "@mui/material/CircularProgress";

import styles from "./CenteredSpinner.module.css";

const CenteredSpinner = () => {
  return (
    <div className={styles.loading_spinner}>
      <CircularProgress style={{ color: "#2f80ed" }} role="presentation" />
    </div>
  );
};

export default CenteredSpinner;
