export const dummyUserData = {
  name: "Ahmed",
  avatarUrl:
    "https://res.cloudinary.com/drru4lsys/image/upload/v1695783051/avatar.webp",
  startingDate: "2018-06-20",
  customReports: ["Custom Report 1"],
  filtersOptions: {
    view: ["Branch Manager", "Board", "Ceo", "Cfo"],
    products: ["Honey Comp", "Coffee Beans", "Somor Honey", "Sidr Honey"],
    regions: ["Ajman", "Sharjah", "Abu Dhabi", "Dubai"],
    currency: ["AED", "EGP", "USD", "EUR"],
  },
};
