import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { dummyInvoices } from "../data/dummyInvoices";

export const getInvoices = createAsyncThunk("/user/getInvoices", async () => {
  const data = dummyInvoices;
  await new Promise((resolve) => setTimeout(resolve, 1500));
  return { data };
});

const initialState = {
  data: null,
  loading: true,
  error: false,
};

const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInvoices.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvoices.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload.data;
      })
      .addCase(getInvoices.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export default invoicesSlice.reducer;
