import React from "react";

import styles from "./SplashScreen.module.css";

const SplashScreen = () => {
  return (
    <div className={styles.splash_screen}>
      <div className={styles.logo_wrapper}>
        <img
          src="https://res.cloudinary.com/drru4lsys/image/upload/v1695782613/full-logo.webp"
          alt="logo"
        />
      </div>
    </div>
  );
};

export default SplashScreen;
