import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "./dashboardSlice";
import reportsReducer from "./reportsSlice";
import invoicesReducer from "./invoicesSlice";
import userReducer from "./userSlice";

export default configureStore({
  reducer: {
    dashboard: dashboardReducer,
    reports: reportsReducer,
    invoices: invoicesReducer,
    user: userReducer,
  },
});
