export const dummyInvoices = [
  {
    id: 1,
    companyName: "Xuru Stays",
    address: [
      "9 Al Posta St.",
      "Roxy, Heliopolis",
      "Cairo Al Qahirah",
      "Egypt",
      "xurufinvo@gmail.com",
    ],
    date: "18 sep 2023",
    invoiceNumber: "INV-1",
    orderNumber: "",
    customerName: "احمد بطاح",
    status: "DRAFT",
    dueDate: "18 sep 2023",
    amount: "AED 500.00",
    balanceDue: "AED 0.00",
    terms: "Due on Receipt",
    subTotal: 500.0,
    total: "AED 500.0",
    paymentMade: 500.0,
    notes: ["Thanks for your business"],
    items: [
      {
        id: 1,
        name: "internet - COGS - Cairo",
        description: "احمد بطاح - انشانت ساند",
        quantity: 1.0,
        rate: 200.0,
        amount: 200.0,
      },
      {
        id: 2,
        name: "internet - COGS - Gouna",
        description: "احمد بطاح - مارينا الجونة",
        quantity: 2.0,
        rate: 100.0,
        amount: 200.0,
      },
      {
        id: 3,
        name: "COGS - Cairo",
        quantity: 1.0,
        rate: 100.0,
        amount: 100.0,
      },
    ],
  },
  {
    id: 2,
    companyName: "Xuru Stays",
    address: [
      "9 Al Posta St.",
      "Roxy, Heliopolis",
      "Cairo Al Qahirah",
      "Egypt",
      "xurufinvo@gmail.com",
    ],
    date: "16 sep 2023",
    invoiceNumber: "انترنت",
    orderNumber: "",
    customerName: "علي الزرقا",
    status: "PAID",
    dueDate: "16 sep 2023",
    amount: "AED 1150.00",
    balanceDue: "AED 0.00",
    terms: "Due on Receipt",
    subTotal: 1150.0,
    total: "AED 1150.0",
    paymentMade: 1150.0,
    notes: ["Thanks for your business"],
    items: [
      {
        id: 1,
        name: "internet - COGS - Cairo",
        description: "علي الزرقا - انشانت ساند",
        quantity: 1.0,
        rate: 250.0,
        amount: 250.0,
      },
      {
        id: 2,
        name: "internet - COGS - Gouna",
        description: "علي الزرقا - مارينا الجونة",
        quantity: 2.0,
        rate: 500.0,
        amount: 1000.0,
      },
      {
        id: 3,
        name: "COGS - Cairo",
        quantity: 1.0,
        rate: 400.0,
        amount: 400.0,
      },
    ],
  },
  {
    id: 3,
    companyName: "Xuru Stays",
    address: [
      "9 Al Posta St.",
      "Roxy, Heliopolis",
      "Cairo Al Qahirah",
      "Egypt",
      "xurufinvo@gmail.com",
    ],
    date: "05 sep 2023",
    invoiceNumber: "INV-000007",
    orderNumber: "",
    customerName: "Mr. Ahmed",
    status: "PAID",
    dueDate: "05 sep 2023",
    amount: "AED 2250.00",
    balanceDue: "AED 0.00",
    terms: "Due on Receipt",
    subTotal: 2250.0,
    total: "AED 2250.0",
    paymentMade: 2250.0,
    notes: ["Thanks for your business"],
    items: [
      {
        id: 1,
        name: "internet - COGS - Cairo",
        description: "Mr. Ahmed - Cario Egypt",
        quantity: 1.0,
        rate: 250.0,
        amount: 250.0,
      },
      {
        id: 2,
        name: "internet - COGS - Gouna",
        description: "Mr. Ahmed - Gouna Egypt",
        quantity: 2.0,
        rate: 500.0,
        amount: 1000.0,
      },
      {
        id: 3,
        name: "COGS - Cairo",
        quantity: 2.0,
        rate: 500.0,
        amount: 1000.0,
      },
    ],
  },
  {
    id: 4,
    companyName: "Xuru Stays",
    address: [
      "9 Al Posta St.",
      "Roxy, Heliopolis",
      "Cairo Al Qahirah",
      "Egypt",
      "xurufinvo@gmail.com",
    ],
    date: "05 sep 2023",
    invoiceNumber: "INV-000006",
    orderNumber: "",
    customerName: "AirBnB",
    status: "PAID",
    dueDate: "05 sep 2023",
    amount: "AED 3000.00",
    balanceDue: "AED 0.00",
    terms: "Due on Receipt",
    subTotal: 3000.0,
    total: "AED 3000.0",
    paymentMade: 3000.0,
    notes: ["Thanks for your business"],
    items: [
      {
        id: 1,
        name: "internet - COGS - Cairo",
        description: "AirBnB - Cairo Egypt",
        quantity: 1.0,
        rate: 1250.0,
        amount: 1250.0,
      },
      {
        id: 2,
        name: "internet - COGS - Gouna",
        description: "AirBnB - Gouna Egypt",
        quantity: 2.0,
        rate: 500.0,
        amount: 1000.0,
      },
      {
        id: 3,
        name: "COGS - Cairo",
        quantity: 1.0,
        rate: 750.0,
        amount: 750.0,
      },
    ],
  },
];
