export const viewOptions = ["all", "actual", "forecast"];

export const typeOptions = [
  "income statement",
  "cash flow statement",
  "balance sheet",
  "sales report",
  "custom reports",
];

export const filterTimeByOptions = ["month", "quarter", "year", "custom"];

export const timeFrameOptions = [
  "today",
  "week",
  "month",
  "quarter",
  "year",
  "custom",
];

export const rangeSelectionOptions = ["trailing", "to date"];

export const timeGranularityOptions = ["daily"];

export const segmentOptions = ["all", "b2b", "b2c"];

export const customersTypeOptions = ["all", "new", "recurring"];

export const productsOptions = [
  "honey comp",
  "coffee beans",
  "somor honey",
  "sidr honey",
];

export const regionsOptions = ["ajman", "sharjah", "abu dhabi", "dubai"];

export const scenarioOptions = ["base case", "best case", "worst case"];

export const currencyOptions = ["AED", "EGP", "USD", "EUR"];

export const typeCategoryMapping = {
  "income statement": "financial",
  "cash flow statement": "financial",
  "balance sheet": "financial",
  "sales report": "management",
  "profitability report": "management",
  "working capital report": "management",
  "custom reports": "custom",
};
