export const dashboardAllData = {
  totalSales: {
    value: "1,234k",
    valueState: {
      percentage: 11.11,
      state: "increase",
    },
    data: [
      {
        x: "Jan",
        y: 140,
      },
      {
        x: "Feb",
        y: 130,
      },
      {
        x: "Mar",
        y: 126,
      },
      {
        x: "Apr",
        y: 145,
      },
      {
        x: "May",
        y: 170,
      },
      {
        x: "Jun",
        y: 130,
      },
      {
        x: "July",
        y: 120,
      },
      {
        x: "Oct",
        y: 130,
      },
      {
        x: "Sep",
        y: 220,
      },
      {
        x: "Nov",
        y: 180,
      },
      {
        x: "Dec",
        y: 200,
      },
    ],
  },
  grossProfitMargin: {
    value: "60.0%",
    valueState: {
      percentage: 16.67,
      state: "decrease",
    },
    data: [
      {
        x: "Jan",
        y: 170,
      },
      {
        x: "Feb",
        y: 150,
      },
      {
        x: "Mar",
        y: 160,
      },
      {
        x: "Apr",
        y: 145,
      },
      {
        x: "May",
        y: 120,
      },
      {
        x: "Jun",
        y: 130,
      },
      {
        x: "July",
        y: 130,
      },
      {
        x: "Oct",
        y: 150,
      },
      {
        x: "Sep",
        y: 200,
      },
      {
        x: "Nov",
        y: 180,
      },
      {
        x: "Dec",
        y: 150,
      },
    ],
  },
  inventoryTurnover: {
    value: "4.1x/month",
    valueState: {
      percentage: 2.22,
      state: "no-change",
    },
    data: [
      {
        x: "Jan",
        y: 110,
      },
      {
        x: "Feb",
        y: 100,
      },
      {
        x: "Mar",
        y: 116,
      },
      {
        x: "Apr",
        y: 145,
      },
      {
        x: "May",
        y: 100,
      },
      {
        x: "Jun",
        y: 130,
      },
      {
        x: "July",
        y: 120,
      },
      {
        x: "Oct",
        y: 130,
      },
      {
        x: "Sep",
        y: 250,
      },
      {
        x: "Nov",
        y: 180,
      },
      {
        x: "Dec",
        y: 180,
      },
    ],
  },
  cashOnHand: {
    value: "113k",
    valueState: {
      percentage: 3.33,
      state: "increase",
    },
    data: [
      {
        x: "Jan",
        y: 140,
      },
      {
        x: "Feb",
        y: 130,
      },
      {
        x: "Mar",
        y: 150,
      },
      {
        x: "Apr",
        y: 140,
      },
      {
        x: "May",
        y: 127,
      },
      {
        x: "Jun",
        y: 120,
      },
      {
        x: "July",
        y: 166,
      },
      {
        x: "Oct",
        y: 140,
      },
      {
        x: "Sep",
        y: 200,
      },
      {
        x: "Nov",
        y: 180,
      },
      {
        x: "Dec",
        y: 185,
      },
    ],
  },
  salesByProduct: [
    {
      product: "Sidr Honey",
      amount: 382000,
      category: "honey",
    },
    {
      product: "Samor Honey",
      amount: 314000,
      category: "honey",
    },
    {
      product: "Honeycomb",
      amount: 165000,
      category: "honey",
    },
    {
      product: "Coffee Beans",
      amount: 160000,
      category: "drinks",
    },
    {
      product: "Green Tea",
      amount: 115000,
      category: "drinks",
    },
    {
      product: "Cola",
      amount: 250000,
      category: "drinks",
    },
    {
      product: "Orange Juice",
      amount: 190000,
      category: "drinks",
    },
  ],
  plBreakdown: [
    {
      name: "sales",
      y: 1234000,
    },
    {
      name: "materials",
      y: -309000,
    },
    {
      name: "freight-in",
      y: -185000,
    },
    {
      name: "Gross Profit",
    },
    {
      name: "Salaries",
      y: -186000,
    },
    {
      name: "rent",
      y: -74000,
    },
    {
      name: "other",
      y: -64000,
    },
    {
      name: "Gross",
    },
    {
      name: "interest",
      y: 103000,
    },
    {
      name: "Taxes",
      y: -185000,
    },
    {
      name: "Net Profit",
    },
  ],
  breakEvenSales: {
    value: "617k",
    valueState: {
      percentage: 2.5,
      state: "increase",
    },
  },
  returnOnEquity: {
    value: "28.1%",
    valueState: {
      percentage: 0.7,
      state: "decrease",
    },
  },
  sales: {
    valueState: {
      percentage: 3.23,
      state: "increase",
    },
    data: [
      {
        x: "Sep",
        y: 700000,
      },
      {
        x: "Oct",
        y: 500000,
      },
      {
        x: "Nov",
        y: 900000,
      },
      {
        x: "Dec",
        y: 950000,
      },
      {
        x: "Jan",
        y: 1000000,
      },
      {
        x: "Feb",
        y: 1200000,
      },
      {
        x: "Mar",
        y: 1500000,
      },
      {
        x: "Apr",
        y: 1600000,
      },
      {
        x: "May",
        y: 1600000,
      },
    ],
  },
  salariesPercentOfSales: {
    valueState: {
      percentage: 0.57,
      state: "increase",
    },
    data: [
      {
        x: "Sep",
        salaries: 2000,
        sales: 200000,
      },
      {
        x: "Oct",
        salaries: 3000,
        sales: 200000,
      },
      {
        x: "Nov",
        salaries: 6000,
        sales: 200000,
      },
      {
        x: "Dec",
        salaries: 4000,
        sales: 160000,
      },
      {
        x: "Jan",
        salaries: 5000,
        sales: 180000,
      },
      {
        x: "Feb",
        salaries: 6000,
        sales: 170000,
      },
      {
        x: "Mar",
        salaries: 7000,
        sales: 400000,
      },
      {
        x: "Apr",
        salaries: 9000,
        sales: 370000,
      },
      {
        x: "May",
        salaries: 12000,
        sales: 400000,
      },
    ],
  },
  salesAndGrossmargin: [
    { region: "Dubai", sales: 173000, grossMargin: "51.2%" },
    { region: "Abu Dhabi", sales: 235000, grossMargin: "52.0%" },
    { region: "Sharjah", sales: 394000, grossMargin: "61.5%" },
    { region: "Ajman", sales: 432000, grossMargin: "66.5%" },
  ],
};
