export const timeFrames = [
  { timeFrame: "Sep 2023", actual: true },
  { timeFrame: "Oct 2023", actual: true },
  { timeFrame: "Nov 2023", actual: true },
  { timeFrame: "Dec 2023", actual: false },
  { timeFrame: "Jan 2024", actual: false },
  { timeFrame: "Feb 2024", actual: false },
  { timeFrame: "Mar 2024", actual: false },
  { timeFrame: "Apr 2024", actual: false },
  { timeFrame: "May 2024", actual: false },
];

export const incomeStatement = [
  [
    {
      name: "Somor honey",
      collapse: true,
      data: [
        123456, 128959, 144723, 159752, 171200, 172303, 164782, 165504, 162130,
      ],
    },
    {
      name: "Honeycomb",
      collapse: true,
      data: [61728, 68593, 73489, 81858, 85413, 93558, 101697, 107256, 119670],
    },
    {
      name: "Coffee Beans",
      collapse: true,
      data: [41152, 42993, 45444, 45850, 47377, 49500, 50127, 51646, 57013],
    },
  ],
  {
    name: "Total Sales",
    data: [
      226336, 240545, 263656, 287460, 303990, 315360, 316607, 324406, 338831,
    ],
  },
  {
    name: "Total COGS",
    data: [
      -122221, -125084, -131828, -132231, -167195, -145066, -167801, -158959,
      -155862,
    ],
  },
  {
    name: "Gross Profit",
    data: [
      104115, 115462, 131828, 155228, 136796, 170294, 148805, 165447, 182968,
    ],
  },
  {
    name: "Gross Profit Margin",
    data: ["46%", "48%", "50%", "54%", "45%", "54%", "47%", "51%", "54%"],
  },
  [
    {
      name: "Salaries",
      collapse: true,
      data: [
        -34750, -34750, -42250, -49750, -59750, -69750, -79750, -87250, -94750,
      ],
    },
    {
      name: "Depreciation",
      collapse: true,
      data: [-3644, -3826, -3979, -4099, -4263, -4390, -4522, -4703, -4891],
    },
    {
      name: "Other Expenses",
      collapse: true,
      data: [-2769, -2755, -3064, -3156, -3410, -3249, -3301, -3762, -3717],
    },
  ],
  {
    name: "Operating Profit",
    data: [62951, 74131, 82535, 98224, 69373, 92905, 61232, 69732, 79610],
  },
  {
    name: "Operating Profit Margin",
    data: ["28%", "31%", "31%", "34%", "23%", "29%", "19%", "21%", "23%"],
  },
  {
    name: "Interest Expense",
    data: [-2500, -2500, -2500, -2500, -3250, -3250, -3250, -3250, -3250],
  },
  {
    name: "Pretax Profit",
    data: [60451, 71631, 80035, 95724, 66123, 89655, 57982, 66482, 76360],
  },
  {
    name: "Income Tax",
    data: [
      -13601, -16117, -18008, -21538, -14878, -21172, -13046, -14958, -17181,
    ],
  },
  {
    name: "Net Profit",
    data: [46850, 55514, 62027, 74186, 51245, 69483, 44936, 51523, 59179],
  },
  {
    name: "Net Profit Margin",
    data: ["21%", "23%", "24%", "26%", "17%", "22%", "14%", "16%", "17%"],
  },
];

export const balanceSheet = [
  [
    {
      name: "Cash",
      collapse: true,
      data: [
        60186, 90249, 125415, 139421, 160791, 222848, 250158, 294247, 343565,
      ],
    },
    {
      name: "Inventory",
      collapse: true,
      data: [40740, 41695, 43943, 44077, 55732, 48355, 55934, 52986, 51954],
    },
    {
      name: "Receivables",
      collapse: true,
      data: [56584, 60136, 65914, 71865, 75998, 78840, 79152, 81102, 84708],
    },
  ],
  {
    name: "Current Assets",
    data: [
      157511, 192080, 235271, 255363, 292520, 350043, 385243, 428335, 480227,
    ],
  },
  {
    name: ["Current Ratio", "Quick Ratio", "Cash Ratio"],
    collapse: true,
    data: [
      [
        "2.09x",
        "2.55x",
        "3.05x",
        "3.21x",
        "3.63x",
        "4.33x",
        "4.85x",
        "5.40x",
        "5.95x",
      ],
      [
        "1.55x",
        "2.00x",
        "2.48x",
        "2.66x",
        "2.94x",
        "3.73x",
        "4.15x",
        "4.73x",
        "5.30x",
      ],
      [
        "0.80x",
        "1.20x",
        "1.62x",
        "1.75x",
        "1.99x",
        "2.75x",
        "3.15x",
        "3.71x",
        "4.25x",
      ],
    ],
  },
  [
    {
      name: "PP&E, net",
      collapse: true,
      data: [37723, 40091, 43943, 72910, 75665, 77560, 77768, 79068, 81472],
    },
    {
      name: "Vehicles",
      collapse: true,
      data: [
        146356, 146174, 146021, 145901, 145737, 145610, 145478, 145297, 145109,
      ],
    },
    {
      name: "Intangible Assets",
      collapse: true,
      data: [
        95683, 96959, 98961, 113504, 114964, 115975, 116145, 116885, 118181,
      ],
    },
  ],
  {
    name: "Fixed Assets",
    data: [
      279762, 283223, 288924, 332316, 336366, 339145, 339391, 341250, 344762,
    ],
  },
  {
    name: "Total Assets",
    data: [
      437273, 475303, 524196, 587678, 628886, 689188, 724634, 769585, 824989,
    ],
  },
  {
    name: ["Total Asset Turnover", "Fixed Asset Turnover"],
    collapse: true,
    data: [
      [
        "0.52x",
        "0.51x",
        "0.50x",
        "0.49x",
        "0.48x",
        "0.46x",
        "0.44x",
        "0.42x",
        "0.41x",
      ],
      [
        "0.81x",
        "0.85x",
        "0.91x",
        "0.87x",
        "0.90x",
        "0.93x",
        "0.93x",
        "0.95x",
        "0.98x",
      ],
    ],
  },
  [
    {
      name: "Payables",
      collapse: true,
      data: [45267, 48109, 52731, 57492, 60798, 63072, 63321, 64881, 67766],
    },
    {
      name: "Short-Term Debt",
      collapse: true,
      data: [30189, 27170, 24453, 22008, 19807, 17826, 16044, 14439, 12995],
    },
  ],
  {
    name: "Current Liabilities",
    data: [75456, 75279, 77184, 79500, 80605, 80898, 79365, 79321, 80761],
  },
  {
    name: "Long-term Debt",
    collapse: true,
    data: [
      221323, 190191, 171172, 154054, 138649, 124784, 112306, 101075, 90968,
    ],
  },
  {
    name: "Non-current Liabilities",
    data: [
      221323, 190191, 171172, 154054, 138649, 124784, 112306, 101075, 90968,
    ],
  },
  {
    name: "Total Liabilities",
    data: [
      286779, 265470, 248356, 233554, 291254, 205682, 191671, 180396, 171729,
    ],
  },
  {
    name: ["Debt-to-Equity", "Debt-to-EBITDA"],
    collapse: true,
    data: [
      [
        "1.91x",
        "1.27x",
        "0.90x",
        "0.66x",
        "0.54x",
        "0.43x",
        "0.36x",
        "0.31x",
        "0.26x",
      ],
      [
        "4.31x",
        "3.41x",
        "2.87x",
        "2.28x",
        "2.98x",
        "2.11x",
        "2.91x",
        "2.42x",
        "2.03x",
      ],
    ],
  },
  {
    name: "Common Shares",
    data: [
      100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000,
    ],
  },
  {
    name: "Retained Earnings",
    data: [
      50494, 109834, 175840, 254124, 309632, 383505, 432963, 489190, 553260,
    ],
  },
  {
    name: "Total Equity",
    data: [
      150494, 209834, 275840, 354124, 409632, 483505, 532963, 589190, 653260,
    ],
  },
  {
    name: "Total Liabilities & Equity",
    data: [
      437293, 475303, 524196, 587678, 628886, 689188, 724634, 724634, 769585,
      824989,
    ],
  },
];

export const cashFlow = [
  [
    {
      name: "Net Income",
      collapse: true,
      data: [46850, 55514, 62027, 74186, 51245, 69483, 44936, 51523, 59179],
    },
    {
      name: "Depreciation",
      collapse: true,
      data: [3644, 3826, 3979, 4099, 4263, 4390, 4522, 4703, 4891],
    },
    {
      name: "Increase in A/R",
      collapse: true,
      data: [-56584, -3552, -5778, -5959, -4133, -2842, -312, -1950, -3606],
    },
    {
      name: "Increase in Inv",
      collapse: true,
      data: [-40740, -954, -2248, -134, -11654, 7376, -7579, 2947, 1032],
    },
    {
      name: "Increase in A/P",
      collapse: true,
      data: [45267, 2842, 4622, 4761, 3306, 2274, 249, 1560, 2885],
    },
  ],
  {
    name: "Operating Cash Flow",
    data: [-1564, 57675, 62602, 76960, 43027, 80681, 41817, 58784, 64381],
  },
  {
    name: "Purch. of Fixed Assets",
    collapse: true,
    data: [-279762, -3461, -5701, -43391, -4051, -2779, -246, -1859, -3512],
  },
  {
    name: "Investing cash flow",
    data: [-279762, -3461, -5701, -43391, -4051, -2779, -246, -1859, -3512],
  },
  [
    {
      name: "Equity Issuance",
      collapse: true,
      data: [100000, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: "Loan Proceeds",
      collapse: true,
      data: [241512, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: "Loan Repayments",
      collapse: true,
      data: [0, -24151, -21736, -19562, -17606, -15846, -14261, -12835, -11551],
    },
    {
      name: "Dividends Paid",
      collapse: true,
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ],
  {
    name: "Financing Cash Flow",
    data: [
      341512, -24151, -21736, -19562, -17606, -15846, -14261, -12835, -11551,
    ],
  },
  {
    name: "Net Cash Flow",
    data: [60186, 30063, 35165, 14006, 21370, 62057, 27310, 44089, 49318],
  },
  {
    name: "Beginning Balance",
    data: [0, 60186, 90249, 125415, 139421, 160791, 222848, 250158, 294247],
  },
  {
    name: "Ending Balance",
    data: [
      60186, 90249, 125415, 139421, 160791, 222848, 250158, 294247, 343565,
    ],
  },
];

const salesReportColumns = ["Sep-23", "Oct-23", "Nov-23", "Aggregate"];

const salesReportRows = [
  {
    name: "total sales",
    data: [226336, 240545, 263656, 730537],
  },
  {
    name: "Volume",
    data: [18107, 19399, 20598, 58104],
  },
  {
    name: "Average Price",
    data: [12.5, 12.4, 12.8, 12.57],
  },
  {
    name: "Budgeted Volume",
    data: [18500, 19000, 20500, 58000],
  },
  {
    name: "Variance",
    data: [-393, 399, 98, 104],
  },
  {
    name: "Impact",
    data: [-4914, 4945, 1256, 1287],
  },
  {
    name: "Budgeted Price",
    data: [12.5, 12.5, 12.5, 12.5],
  },
  {
    name: "Variance",
    data: [0.0, -0.1, 0.3, 0.07],
  },
  {
    name: "Impact",
    data: [0, -1940, 6179, 4240],
  },
  {
    name: "Breakeven Sales",
    data: [104115, 115462, 131828, 351404],
  },
  {
    name: "Breakeven Quantity",
    data: [8329, 9311, 10299, 27940],
  },
  {
    name: "Margin of Safety",
    data: ["117%", "108%", "100%", "108%"],
  },
];

const customReportData = {
  columns: ["Sep-23", "Oct-23", "Nov-23", "Dec-23", "Aggregate"],
  variables: [
    {
      title: "Total Sales By Product",
      x: "timeframe",
      y: "product",
      rows: [
        {
          name: "Honey Comp",
          data: [49794, 52920, 58004, 160718, 321436],
        },
        {
          name: "Coffee Beans",
          data: [56584, 60136, 65914, 182634, 365268],
        },
        {
          name: "Somor honey",
          data: [79218, 84191, 92280, 255688, 511377],
        },
        {
          name: "Sidir honey",
          data: [40740, 43298, 47458, 131497, 262993],
        },
        {
          name: "Total",
          data: [226336, 240545, 263656, 730537, 1461074],
        },
      ],
    },
    {
      title: "Volume Impact By Region",
      x: "timeframe",
      y: "region",
      rows: [
        {
          name: "Ajman",
          data: ["(1081)", "1088", "276", "0", "283"],
        },
        {
          name: "Sharjah",
          data: ["(1229)", "1236", "314", "0", "321"],
        },
        {
          name: "Abu Dhabi",
          data: ["(1720)", "1731", "440", "0", "451"],
        },
        {
          name: "Dubai",
          data: ["(855)", "890", "226", "0", "261"],
        },
        {
          name: "Total",
          data: ["(4885)", "4945", "1256", "0", "1316"],
        },
      ],
    },
    {
      title: "Price variance By Product",
      x: "timeframe",
      y: "product",
      rows: [
        {
          name: "Honey Comp",
          data: ["0.0%", "(2.2%)", "(6.6%)", "0.0%", "(8.8%)"],
        },
        {
          name: "Coffee Beans",
          data: ["1.0%", "(2.5%)", "7.5%", "0.0%", "6.0%"],
        },
        {
          name: "Somor honey",
          data: ["0.0%", "(3.5%)", "10.5%", "0.0%", "7.0%"],
        },
        {
          name: "Sidir honey",
          data: ["(1.0%)", "(1.8%)", "5.4%", "0.0%", "2.6%"],
        },
        {
          name: "Total",
          data: ["0.0%", "(10.0%)", "16.8%", "0.0%", "6.8%"],
        },
      ],
    },
  ],
};

export const generateReportsData = (filters) => {
  let data = {};
  switch (filters.type) {
    case "income statement":
      data = {
        timeFrames,
        rows: incomeStatement,
      };
      break;
    case "cash flow statement":
      data = {
        timeFrames,
        rows: cashFlow,
      };
      break;
    case "balance sheet":
      data = {
        timeFrames,
        rows: balanceSheet,
      };
      break;
    case "sales report":
      data = {
        timeFrames: salesReportColumns,
        rows: salesReportRows,
      };
      break;
    case "custom reports":
      data = customReportData;
      break;
    default:
      data = {};
      break;
  }
  return data;
};
